import type React from 'react';
import type { Icon } from 'react-feather';
interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string;
  icon?: Icon;
  iconPosition?: 'start' | 'end';
  iconColor?: 'red' | 'green' | 'grey';
  iconFill?: boolean;
  buttonStyle?: 'regular' | 'action' | 'alert' | 'flush';
}
export function PushToTalkButton({
  label = 'Okay',
  icon = void 0,
  iconPosition = 'start',
  iconColor = void 0,
  iconFill = false,
  buttonStyle = 'regular',
  ...rest
}: ButtonProps) {
  const StartIcon = iconPosition === 'start' ? icon : null;
  const EndIcon = iconPosition === 'end' ? icon : null;
  const baseClasses = 'flex items-center gap-2 font-mono text-xs rounded-full px-6 py-2 min-h-[42px] transition-all outline-none';
  const styleClasses = {
    regular: 'bg-[#ececf1] text-[#101010] hover:bg-[#d8d8d8] disabled:text-[#999]',
    action: 'bg-[#101010] text-[#ececf1] hover:bg-[#404040] disabled:text-[#999]',
    alert: 'bg-red-600 text-[#ececf1] hover:bg-red-600 disabled:text-[#999]',
    flush: 'bg-transparent disabled:text-[#999]'
  };
  const iconClasses = {
    red: 'text-[#cc0000]',
    green: 'text-[#009900]',
    grey: 'text-[#909090]'
  };
  return <button data-component="Button" className={`
        ${baseClasses}
        ${styleClasses[buttonStyle]}
        enabled:cursor-pointer
        enabled:active:translate-y-[1px]
      `} {...rest} data-sentry-component="PushToTalkButton" data-sentry-source-file="PushToTalkButton.tsx">
      {StartIcon && <span className={`
          flex -ml-2
          ${iconColor ? iconClasses[iconColor] : ''}
          ${iconFill ? 'fill-current' : ''}
        `}>
          <StartIcon className="w-4 h-4" />
        </span>}
      <span>{label}</span>
      {EndIcon && <span className={`
          flex -mr-2
          ${iconColor ? iconClasses[iconColor] : ''}
          ${iconFill ? 'fill-current' : ''}
        `}>
          <EndIcon className="w-4 h-4" />
        </span>}
    </button>;
}